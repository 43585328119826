<template>
  <div>
    <!-- Filters -->
    <the-search-filter
      i18nPrefix="organizations"
      :loading="state.loading"
      :searchFilter="state.searchFilter"
      @clear="clearSearchFilter()"
      @filterInput="param => updateInput(param)"
      @search="() => loadOrganizations(1)"
    />

    <!-- Title -->
    <va-card>
      <va-card-title class="cardTitle">
        {{ $t("organizations.title") }}
        <va-button class="ml-2" color="primary" @click="openCreateOrgModal()">
          {{ $t("organizations.create") }}
        </va-button>
      </va-card-title>

      <!-- Table -->
      <va-card-content>
        <va-data-table
          :no-data-html="$t('organizations.noResults')"
          :items="state.organizations"
        >
          <template #header(organization)>{{
            $t("organizations.name")
          }}</template>
          <template #header(id)>{{ $t("organizations.id") }}</template>
          <template #header(createdAt)>
            {{ $t("organizations.createdAt") }}
          </template>

          <template #cell(organization)="{ source: org }">
            <router-link :to="{ path: organizationLink(org.id) }">
              {{ org.name }}
            </router-link>
          </template>

          <template #cell(id)="{ source: id }">
            <router-link :to="{ path: organizationLink(id) }">
              {{ id }}
            </router-link>
            <button-clipboard-copy
              :field="$t('organizations.id')"
              :value="id"
            />
          </template>

          <template #cell(createdAt)="{ source: createdAt }">
            <td>
              {{ printDate(createdAt) }}
            </td>
          </template>
        </va-data-table>
      </va-card-content>
      <va-card-content>
        <va-pagination
          class="pagination"
          input
          color="#000000"
          :hide-on-single-page="true"
          v-model="state.currentPage"
          :pages="state.totalPages"
          :page-size="state.perPage"
        />
      </va-card-content>
    </va-card>
  </div>
  <create-org-modal
    :isOpen="state.createOrgModalIsOpen"
    @cancel="state.createOrgModalIsOpen = false"
    @create="handleCreateOrg"
  />
</template>

<script setup>
import { reactive, watch, onMounted, inject } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { callFetchOrganizations, callCreateOrganization } from "@/api/identity";
import ButtonClipboardCopy from "@/components/ButtonClipboardCopy.vue";
import TheSearchFilter from "@/components/TheSearchFilter.vue";
import { printDate } from "@/utils";
import { organizationLink } from "@/utils/identity";
import CreateOrgModal from "@/components/modal/CreateOrganizationModel.vue";

const handleError = inject("handleError");
const toasts = inject("toasts");
const router = useRouter();
const { t } = useI18n();

const state = reactive({
  organizations: [],
  searchFilter: {
    search: { value: undefined, acceptedValues: "string", label: "name" }
  },
  loading: false,
  lastLoadedPage: undefined,
  currentPage: 1,
  totalItems: 0,
  perPage: 10,
  totalPages: 0,
  createOrgModalIsOpen: false
});
/**
 * Open the create organization modal
 */
function openCreateOrgModal() {
  state.createOrgModalIsOpen = true;
}
/**
 * Clear search filter values
 */
function clearSearchFilter() {
  const searchFilterKeys = Object.keys(state.searchFilter);
  searchFilterKeys.forEach(key => {
    state.searchFilter[key].value = undefined;
  });
  loadOrganizations(1);
}
/**
 * Handle the creation of a new organization
 * @param {string} organizationName - The name of the new organization
 */
async function handleCreateOrg(organizationName) {
  try {
    const response = await callCreateOrganization({ name: organizationName });
    state.createOrgModalIsOpen = false;
    toasts?.info(t("organizations.creationSuccess"));
    const newOrgId = response.data.id;
    await loadOrganizations(state.currentPage); // Reload the organization list
    router.push({ path: `/admin/identity/organizations/${newOrgId}` }); // Navigate to the newly created organization's detail page
  } catch (error) {
    handleError(error);
  }
}

/**
 * Update a searchFilter property's value
 * @param {Object} param
 * @param {string} param.inputKey
 * @param {any} param.value - depends on which field is updated
 */
function updateInput(param) {
  console.log(param);
  const paramKey = param.inputKey;
  const paramValue = param.value;
  state.searchFilter[paramKey].value = paramValue;
}

/**
 * Create the parameters object for the request and call it.
 * Then calculate the page properties's values.
 * @param {number} page - positive integer
 */
async function loadOrganizations(page = 1) {
  if (state.loading === true || page === state.lastLoadedPage) {
    return;
  }

  state.lastLoadedPage = page;
  state.loading = true;

  const params = {
    count: state.perPage,
    page
  };
  Object.keys(state.searchFilter).forEach(key => {
    params[key] = state.searchFilter[key].value;
  });

  try {
    const resp = await callFetchOrganizations({ params });
    state.organizations = resp.data.items;
    state.organizations = resp.data.items.map(item => {
      return {
        organization: {
          name: item.name,
          id: item.id
        },
        id: item.id,
        createdAt: item.createdAt
      };
    });

    state.totalItems = resp.data.total;
    state.currentPage = page;
    state.totalPages =
      Math.floor(resp.data.total / state.perPage) +
      (resp.data.total % state.perPage === 0 ? 0 : 1);
  } catch (e) {
    handleError(e);
  } finally {
    state.loading = false;
  }
}

watch(
  () => state.currentPage,
  async currentPage => {
    await loadOrganizations(currentPage);
  }
);

onMounted(async () => {
  await loadOrganizations();
});
</script>
<style scoped>
.card-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
