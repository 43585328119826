<template>
  <va-modal
    v-model="state.isOpen"
    hide-default-actions
    :title="$t('organizations.createModal.title')"
  >
    <slot>
      <va-input
        v-model="state.organizationName"
        label="Organization Name"
        placeholder="Enter organization name"
      />
    </slot>
  
    <template #footer>
      <va-button flat outline class="mr-1" @click="emit('cancel')">
        {{ $t("buttons.cancel") }}
      </va-button>
      <va-button class="ml-1" color="primary" @click="createOrganization">
        {{ $t("buttons.submit") }}
      </va-button>
    </template>
  </va-modal>
</template>
  
  <script setup>
  import { computed, reactive } from "vue";
  
  const props = defineProps({
    isOpen: {
      type: Boolean,
      default: false
    }
  });
  
  const state = reactive({
    isOpen: computed(() => props.isOpen),
    organizationName: ''
  });
  
  const emit = defineEmits(["cancel", "create"]);
  
  const createOrganization = () => {
    if (state.organizationName.trim()) {
      // Emit the create event with the organization name
      emit("create", state.organizationName);
      // Clear the input field
      state.organizationName = '';
    }
  };
  </script>
  
  <style scoped>
  .va-input {
    margin-bottom: 1rem;
  }
  </style>
  